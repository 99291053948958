import { Routes, Route } from 'react-router-dom';
import Whoops404 from './components/4_Whoops404/Whoops404';
import AllProject from './components/AllProject/AllProject';
import About from './components/pagesMenuLinks/About/About';
import Price from './components/pagesMenuLinks/Price/Price';
import Contact from './components/pagesMenuLinks/Contact/Contact';
import Article1 from './components/pagesMenuLinks/Articles/1_Article.jsx';
import Article2 from './components/pagesMenuLinks/Articles/2_Article.jsx';
import Article3 from './components/pagesMenuLinks/Articles/3_Article.jsx';
import Article4 from './components/pagesMenuLinks/Articles/4_Article.jsx';
import Article5 from './components/pagesMenuLinks/Articles/5_Article.jsx';
import Usluga from './components/pagesMenuLinks/Uslugi/Usluga.jsx';
import Usluga1 from './components/pagesMenuLinks/Uslugi/Usluga1.jsx';
import Usluga2 from './components/pagesMenuLinks/Uslugi/Usluga2.jsx';
import Usluga3 from './components/pagesMenuLinks/Uslugi/Usluga3.jsx';
import Usluga4 from './components/pagesMenuLinks/Uslugi/Usluga4.jsx';
import Usluga5 from './components/pagesMenuLinks/Uslugi/Usluga5.jsx';
import Usluga6 from './components/pagesMenuLinks/Uslugi/Usluga6.jsx';
import Usluga7 from './components/pagesMenuLinks/Uslugi/Usluga7.jsx';
import Usluga8 from './components/pagesMenuLinks/Uslugi/Usluga8.jsx';
import Usluga9 from './components/pagesMenuLinks/Uslugi/Usluga9.jsx';

import './App.css';

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<AllProject />} />
        <Route path="/about" element={<About />} />
        <Route path="/price" element={<Price />} />
        <Route path="/article1" element={<Article2 />} />
        <Route path="/article2" element={<Article3 />} />
        <Route path="/article3" element={<Article1 />} />
        <Route path="/article4" element={<Article4 />} />
        <Route path="/article5" element={<Article5 />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/usluga" element={<Usluga />} />
        <Route path="/usluga1" element={<Usluga1 />} />
        <Route path="/usluga2" element={<Usluga2 />} />
        <Route path="/usluga3" element={<Usluga3 />} />
        <Route path="/usluga4" element={<Usluga4 />} />
        <Route path="/usluga5" element={<Usluga5 />} />
        <Route path="/usluga6" element={<Usluga6 />} />
        <Route path="/usluga7" element={<Usluga7 />} />
        <Route path="/usluga8" element={<Usluga8 />} />
        <Route path="/usluga9" element={<Usluga9 />} />
        <Route path="*" element={<Whoops404 />} />
      </Routes>
    </>
  );
}

export default App;
