import './Msg.css';
import { Link } from 'react-router-dom';
import whatsapp from '../../../img/msg/whatsapp.png';
import telegram from '../../../img/msg/telegram.png';
// import viber from '../../../img/msg/viber.png';

export function WhatsApp() {
  return (
    <>
      <div className="whatsap">
        <Link to="https://wa.me/33767816986" target="_blank">
          <img src={whatsapp} alt="WhatsApp" title="WhatsApp" />
        </Link>
      </div>
    </>
  );
}

// export function Viber() {
//   return (
//     <>
//       <div className="viber">
//         <Link to="viber://chat?number=380972361907" target="_blank">
//           <img src={viber} alt="Viber" title="Viber" />
//         </Link>
//       </div>
//     </>
//   );
// }
export function Telegram() {
  return (
    <>
      <div className="telegram">
        <Link to="https://t.me/33767816986" target="_blank">
          <img src={telegram} alt="Viber" title="Telegram" />
        </Link>
      </div>
    </>
  );
}
