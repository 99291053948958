import Header from '../../1_Header/Container/Header';
import Footer from '../../3_Footer/Footer';
import './Price.css';

import WhatsApp from '../../WhatsUp/WhatsappLink';

export function PriceTable() {
  return (
    <>
      <div className="price">
        <div className="price-title">
          <h1>Tarifs des prestations:</h1>
        </div>
        <div className="price-padding">
          <div className="outsidewidthtable">
            <p className="lastquestion">
              <span>
                Les prix indiqués ci-dessous sont indicatifs. Le prix dépend du
                degré de saleté et de la quantité commandée. Vous pouvez
                connaître le prix final en nous appelant ou en envoyant une
                photo de ce qui doit être nettoyé, et notre employé vous donnera
                un devis précis.
              </span>
            </p>
            <table className="table">
              <tbody>
                <tr>
                  <th className="thleft">Nom des prestations</th>
                  <th>Coût (EUR)</th>
                </tr>
                <tr>
                  <td colSpan="2" className="priceOne">
                    LISTE DE PRIX DE LAVAGE DES REVÊTEMENTS DE MEUBLES:
                  </td>
                </tr>
                <tr>
                  <td>Chaise</td>
                  <td className="center"> od 10 do 30</td>
                </tr>
                <tr>
                  <td>Pouf</td>
                  <td className="center"> od 20 do 50</td>
                </tr>
                <tr>
                  <td>Fauteuil</td>
                  <td className="center">od 20 do 80</td>
                </tr>
                <tr>
                  <td>Canapé</td>
                  <td className="center">od 80 do 200</td>
                </tr>
                <tr>
                  <td>Coin</td>
                  <td className="center">od 150 do 300</td>
                </tr>
                <tr>
                  <td> Matelas</td>
                  <td className="center"> od 100 do 300</td>
                </tr>
                <tr>
                  <td>Laver les peluches</td>
                  <td className="center"> od 10 do 200</td>
                </tr>
                <tr>
                  <td>Lavage des nacelles pour poussettes</td>
                  <td className="center"> od 50 do 200</td>
                </tr>
                <tr>
                  <td>Laver les sièges auto pour enfants</td>
                  <td className="center">od 50 do 200</td>
                </tr>

                <tr>
                  <td colSpan="2" className="priceOne">
                    LISTE DE PRIX LAVAGE DE TAPIS:
                  </td>
                </tr>

                <tr>
                  <td>Tapis </td>
                  <td className="center"> od 10 do 40 €/m2</td>
                </tr>

                <tr>
                  <td>Сarpette</td>
                  <td className="center"> od 2 do 30 €/m2</td>
                </tr>
                <tr>
                  <td colSpan="2" className="priceOne">
                    Nettoyage dans la voiture:
                  </td>
                </tr>
                <tr>
                  <td>
                    Lavage de base des tissus d'ameublement (sièges avant)
                  </td>
                  <td className="center"> od 50 do 100</td>
                </tr>
                <tr>
                  <td>Lavage avancé (fauteuils, canapé)</td>
                  <td className="center"> od 150 do 200</td>
                </tr>
                <tr>
                  <td>
                    Buanderie experte (sièges, canapé, côtés, moquette, ciel de
                    toit, coffre)
                  </td>
                  <td className="center"> od 350 do 1000</td>
                </tr>
              </tbody>
            </table>
            <p className="lastquestion">
              {' '}
              <span>Nous vous invitons à utiliser nos services!</span>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

function Price() {
  const obj = {
    mainLang: 'PL',
    path: '/en/price',
    langP: 'Poland',
    langEng: 'English',
  };
  return (
    <div className="body_wrapper">
      <Header obj={obj} />

      <PriceTable />
      <WhatsApp />
      <Footer />
    </div>
  );
}
export default Price;
