import Header from '../../1_Header/Container/Header';
import Footer from '../../3_Footer/Footer';
import './Articles.css';
import quilon from '../../../img/articles/diwanow2.png';
import quilon2 from '../../../img/articles/article3.3.jpg';
import { Cataloge } from './1_Article';
import WhatsApp from '../../WhatsUp/WhatsappLink';

function Quilon() {
  return (
    <>
      <div className="containerarticel">
        <div className="atricelandcatalog">
          <div className="articelquilon">
            <h2>Laver les tapis et moquettes Lyon</h2>

            <p>
              Le lavage des tapis et moquettes est une activité importante qui
              affecte la propreté et l'hygiène de nos maisons. Malgré un passage
              régulier de l'aspirateur, divers types de contaminants peuvent
              s'accumuler sur ces surfaces, ce qui nuit non seulement à
              l'esthétique, mais aussi à notre santé. Dans cet article, nous
              présenterons plusieurs raisons pour lesquelles le lavage des tapis
              et moquettes est si important.
            </p>
            <p>
              Tout d’abord, laver les tapis et moquettes permet d’éliminer les
              contaminants tels que les bactéries, les virus, les acariens, la
              poussière et le pollen. Ces facteurs peuvent affecter notre santé,
              notamment pour les personnes souffrant d’allergies et d’asthme.
              Laver régulièrement les tapis et moquettes peut aider à réduire
              l’apparition de réactions allergiques et à prévenir la propagation
              de maladies infectieuses.
            </p>
            <img className="quilon" src={quilon} title="quilon" alt="quilon" />
            <p>
              Deuxièmement, les tapis et moquettes sont exposés à divers types
              de taches et de saletés, comme l'huile, le café ou le vin. Avec le
              temps, ces taches deviennent plus difficiles à éliminer. Un lavage
              régulier peut aider à éliminer ces taches et à garder votre tapis
              ou moquette en bon état plus longtemps.
            </p>
            <p>
              Troisièmement, laver régulièrement vos tapis et moquettes peut
              aider à conserver leur apparence et leur durabilité. Au fil du
              temps, des contaminants peuvent s’accumuler sur ces surfaces, ce
              qui peut affecter leur structure et leur durabilité. Le lavage
              élimine ces contaminants et améliore la qualité de la surface, ce
              qui affecte positivement l’apparence et la durabilité des tapis et
              moquettes.
            </p>
            <img
              className="quilon2"
              src={quilon2}
              title="quilon"
              alt="quilon"
            />
            <p>
              Quatrièmement, le lavage des tapis et moquettes est également une
              question d’odeurs. Au fil du temps, les odeurs de l’environnement
              peuvent s’accumuler sur ces surfaces, notamment dans les endroits
              où l’on fume, cuisine ou élève des animaux. Un lavage régulier
              élimine ces odeurs et rafraîchit les surfaces.
            </p>
            <p>
              En résumé, laver les tapis et moquettes est une activité
              importante qui affecte la propreté et l’hygiène de nos maisons. Un
              lavage régulier élimine la saleté, les taches et les odeurs et
              maintient les tapis et moquettes en bon état pendant une période
              plus longue. Il vaut donc la peine de se laver régulièrement pour
              garantir un environnement propre et sain dans nos maisons.
            </p>
          </div>
          <Cataloge />
        </div>
      </div>
    </>
  );
}

function Article3() {
  const obj = {
    mainLang: 'PL',
    path: '/en/article3',
    langP: 'Poland',
    langEng: 'English',
  };
  return (
    <div className="body_wrapper">
      <Header obj={obj} />
      <WhatsApp />

      <Quilon />
      <Footer />
    </div>
  );
}
export default Article3;
