import Header from '../../1_Header/Container/Header';
import Footer from '../../3_Footer/Footer';
import SwiperUslugi from './SwiperUslugi3';
import UslugiList from '../../../UI/ListUslugi/ListUslugi';
import './Uslugi.css';

function UslugiImg() {
  return (
    <div className="mySwiper-uslugi">
      <SwiperUslugi />
    </div>
  );
}

function UslugiText() {
  return (
    <>
      <div className="uslugi__text">
        <h2> Laver les fauteuils</h2>
        <p>
          Le nettoyage à sec du fauteuil est une étape importante dans
          l'entretien du meuble, qui permet de conserver son aspect soigné et de
          prolonger sa durée de vie. Au cours d'une utilisation quotidienne, les
          fauteuils accumulent de la poussière, des taches et des bactéries, ce
          qui non seulement gâche leur apparence, mais peut également provoquer
          des réactions allergiques. Le nettoyage à sec professionnel élimine
          efficacement la contamination à l'aide d'agents spéciaux qui
          n'endommagent pas le matériau, laissant les meubles frais et propres.
        </p>
        <h3>Le processus de nettoyage à sec comprend plusieurs étapes:</h3>
        <p>
          Le processus de nettoyage à sec des chaises comprend plusieurs étapes
          soigneusement réalisées pour garantir les meilleurs résultats
          possibles. Tout d’abord, la chaise est pré-nettoyée pour éliminer les
          saletés plus grosses telles que la poussière et les débris. Ensuite,
          des produits de nettoyage spéciaux sont appliqués sur la surface du
          rembourrage, sélectionnés en fonction du type de matériau -
          différentes préparations sont utilisées pour les tissus et d'autres
          pour le cuir ou le daim. Ces agents pénètrent profondément dans le
          tissu, dissolvant les taches et éliminant les bactéries. Après cette
          étape, l'excès d'humidité et les résidus de saleté sont efficacement
          éliminés à l'aide de dispositifs spéciaux, grâce auxquels la chaise a
          non seulement l'air fraîche, mais sèche également rapidement.
        </p>
        <p>
          Le résultat d’un tel nettoyage n’est pas seulement le rafraîchissement
          esthétique des meubles, mais aussi l’amélioration de l’hygiène dans
          l’espace de vie.
        </p>
      </div>
    </>
  );
}

function UslugiImgList() {
  return (
    <div className="uslugi">
      <div className="container">
        <div className="wrapper__uslugi">
          <div className="wrapper__img-text">
            <UslugiImg />
            <UslugiText />
          </div>
          <UslugiList />
        </div>
      </div>
    </div>
  );
}
function Usluga3() {
  const obj = {
    mainLang: 'PL',
    path: '/en/usluga3',
    langP: 'Poland',
    langEng: 'English',
  };
  return (
    <>
      <div className="body_wrapper">
        <Header obj={obj} />
        <UslugiImgList />
        <Footer />
      </div>
    </>
  );
}

export default Usluga3;
