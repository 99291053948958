import Header from '../../1_Header/Container/Header';
import Footer from '../../3_Footer/Footer';
import SwiperUslugi from './SwiperUslugi5';
import UslugiList from '../../../UI/ListUslugi/ListUslugi';
import './Uslugi.css';

function UslugiImg() {
  return (
    <div className="mySwiper-uslugi">
      <SwiperUslugi />
    </div>
  );
}

function UslugiText() {
  return (
    <>
      <div className="uslugi__text">
        <h2>Coin lavage à Lyon</h2>
        <p>
          Le lavage des coins est un processus qui nécessite la bonne technique
          et les bons outils pour éliminer efficacement la saleté, les taches et
          les bactéries. Un bon nettoyage des coins améliore non seulement leur
          apparence, mais prolonge également leur durée de vie. Il est important
          d’entretenir régulièrement les meubles rembourrés, notamment ceux qui
          sont utilisés de manière intensive.
        </p>
        <p>
          Avant de laver le canapé d'angle, il convient de vérifier de quel
          matériau il est fait. Certains tissus nécessitent des produits de
          nettoyage spécialisés, tandis que d’autres peuvent être plus
          résistants à l’humidité. N’oubliez pas également de passer
          l’aspirateur régulièrement pour éliminer la poussière et les
          contaminants de surface.
        </p>
        <p>
          Les services professionnels de nettoyage de coins utilisent souvent
          des méthodes à vapeur ou des extracteurs spéciaux qui permettent un
          nettoyage en profondeur du tissu sans risquer de l'endommager.
          L'utilisation régulière de tels services peut améliorer
          considérablement l'hygiène de la maison et l'esthétique des meubles.
        </p>
      </div>
    </>
  );
}

function UslugiImgList() {
  return (
    <div className="uslugi">
      <div className="container">
        <div className="wrapper__uslugi">
          <div className="wrapper__img-text">
            <UslugiImg />
            <UslugiText />
          </div>
          <UslugiList />
        </div>
      </div>
    </div>
  );
}
function Usluga5() {
  const obj = {
    mainLang: 'PL',
    path: '/en/usluga5',
    langP: 'Poland',
    langEng: 'English',
  };
  return (
    <>
      <div className="body_wrapper">
        <Header obj={obj} />
        <UslugiImgList />
        <Footer />
      </div>
    </>
  );
}

export default Usluga5;
