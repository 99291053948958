import Header from '../../1_Header/Container/Header';
import Footer from '../../3_Footer/Footer';
import SwiperUslugi from './SwiperUslugi2';
import UslugiList from '../../../UI/ListUslugi/ListUslugi';
import './Uslugi.css';

function UslugiImg() {
  return (
    <div className="mySwiper-uslugi">
      <SwiperUslugi />
    </div>
  );
}

function UslugiText() {
  return (
    <>
      <div className="uslugi__text">
        <h2> Sellerie automobile à Lyon</h2>
        <p>
          L'intérieur de la voiture nécessite une rénovation environ tous les 60
          à 120 000 kilomètres, en fonction du matériau et des conditions de
          fonctionnement. Le nettoyage à sec des voitures à Lyon est un service
          apprécié des spécialistes de la société « PH Service », qui luttent
          quotidiennement contre les taches sur les tissus et le cuir naturel
          des sièges auto.
        </p>
        <h3>Quels contaminants peuvent être éliminés:</h3>
        <p>
          La société "PH Service" lutte contre les taches de boissons et
          d'aliments, les taches de vêtements usés et les traces d'animaux. De
          manière générale, le nettoyage à sec de l'intérieur de la voiture
          permet de redonner entièrement la couleur d'origine à la matière, de
          la rafraîchir, de lui redonner sa texture et de lui rendre sa
          fraîcheur. Après nettoyage par des spécialistes, un arôme agréable
          sera perceptible dans l'habitacle. Nous effectuons les détails
          uniquement en utilisant des produits de nettoyage certifiés et de
          haute qualité, totalement sûrs au contact de la peau humaine et
          animale.
        </p>
        <h3>Comment enlever les taches de l'intérieur d'une voiture:</h3>
        <ul>
          <li>
            Nettoyage des sièges avec un aspirateur spécial (nettoyage à sec) ;
          </li>
          <li>
            Nettoyer les endroits difficiles d'accès avec une solution
            désinfectante (dans les conduits de ventilation, les fissures, sous
            les sièges, les coutures des selleries, etc.) ;
          </li>
          <li>
            Traitement des sièges, des revêtements de plafond et des tapis avec
            de la mousse à effet détachant et nettoyant en profondeur ;
          </li>
          <li>
            La mousse et le désinfectant sont éliminés à l'aide d'un aspirateur
            spécial eau sous haute pression (permet d'obtenir une base presque
            sèche) ;
          </li>
          <li>
            Application d'une substance spéciale sur les plastiques et le cuir
            pour redonner brillance et propreté.
          </li>
        </ul>
        <p>
          Grâce à cela, le salon reste impeccablement propre et sent bon pendant
          longtemps. Ce traitement peut être commandé aussi bien pour les
          voitures avec intérieur en cuir que pour les voitures avec revêtement
          de siège en textile. Selon le matériau, différents produits et
          appareils de nettoyage sont utilisés.
        </p>
      </div>
    </>
  );
}

function UslugiImgList() {
  return (
    <div className="uslugi">
      <div className="container">
        <div className="wrapper__uslugi">
          <div className="wrapper__img-text">
            <UslugiImg />
            <UslugiText />
          </div>
          <UslugiList />
        </div>
      </div>
    </div>
  );
}
function Usluga2() {
  const obj = {
    mainLang: 'PL',
    path: '/en/usluga2',
    langP: 'Poland',
    langEng: 'English',
  };
  return (
    <>
      <div className="body_wrapper">
        <Header obj={obj} />
        <UslugiImgList />
        <Footer />
      </div>
    </>
  );
}

export default Usluga2;
