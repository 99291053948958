import Header from '../../1_Header/Container/Header';
import Footer from '../../3_Footer/Footer';
import foto from '../../../img/about/about.jpg';
import './About.css';
import WhatsApp from '../../WhatsUp/WhatsappLink';

function AboutTable() {
  return (
    <div className="about">
      <div className="about-title">
        <h1>À propos de nous</h1>
      </div>
      <div className="container about-container">
        <div className="about__content">
          <div className="about__content-text">
            <h2>
              Chers clients, nous sommes heureux de vous accueillir sur notre
              site Internet !
            </h2>
            <p>
              La société <strong>"PH Service"</strong> est une entreprise leader
              dans le secteur des services de nettoyage, proposant un nettoyage
              de haute qualité aux clients commerciaux et privés. Fondée pour
              offrir les plus hauts standards de propreté, l’entreprise s’est
              rapidement bâtie une réputation de professionnalisme et
              d’attention aux détails. Chaque employé de Czysty Dom Waw suit une
              formation approfondie pour assurer un nettoyage efficace et sûr à
              l'aide d'équipements modernes et de produits de nettoyage
              écologiques.
            </p>
            <div className="about__content-img">
              <img src={foto} alt="О нас" />
            </div>
            <p>
              La philosophie de base de "PH Service" est d'offrir un maximum de
              confort et de satisfaction client. L'entreprise propose une
              approche individuelle pour chaque commande, en développant des
              plans de nettoyage spéciaux qui prennent en compte tous les
              souhaits et besoins des clients. Cela garantit non seulement la
              propreté, mais aussi une atmosphère saine dans les chambres.
            </p>
            <p>
              Notre entreprise offre une large gamme de services tels que le
              nettoyage des surfaces, le détachage, l'élimination des odeurs, la
              désinfection, l'aspiration et bien plus encore. Chaque service est
              adapté aux besoins individuels du client pour garantir les
              meilleurs résultats possibles.
            </p>
            <p>
              L'un des principaux avantages de "PH Service" est la flexibilité
              et la rapidité de réponse aux besoins des clients. L'entreprise
              garantit une exécution rapide des commandes à un moment qui
              convient aux clients, ce qui permet de minimiser les désagréments
              et les interruptions de travail. "PH Service" améliore constamment
              ses méthodes et technologies de nettoyage, en introduisant des
              solutions modernes pour augmenter l'efficacité et la qualité de
              ses services.
            </p>
            <p>
              <strong> "PH Service"</strong> s'efforce de devenir un leader dans
              l'industrie du nettoyage, en proposant des solutions innovantes et
              écologiques à ses clients. La coopération avec "PH Service" est la
              garantie d'une propreté irréprochable et d'un haut niveau de
              service.
            </p>
            <p>Nous opérons à Lyon et dans ses environs. </p>
          </div>
        </div>
      </div>
    </div>
  );
}

function About() {
  const obj = {
    mainLang: 'PL',
    path: '/en/about',
    langP: 'Poland',
    langEng: 'English',
  };
  return (
    <>
      <div className="body_wrapper">
        <Header obj={obj} />
        <AboutTable />
        <WhatsApp />
        <Footer />
      </div>
    </>
  );
}
export default About;
