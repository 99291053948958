import Main from '../2_Main/Container/Main';
import Footer from '../3_Footer/Footer';
import LineUp from '../LineUp/LineUp';

import WhatsApp from '../WhatsUp/WhatsappLink';
import Header from '../1_Header/Container/Header';

import css from './AllProject.module.css';
import LinkForma from '../LinkForma/LinkForma';
function AllProject() {
  const obj = {
    mainLang: 'PL',
    path: '/en/main',
    langP: 'Poland',
    langEng: 'English',
  };
  return (
    <>
      <div className={css.body_wrapper}>
        <LinkForma />
        <Header obj={obj} />
        <Main />
        <WhatsApp />
        <LineUp />
        <Footer />
      </div>
    </>
  );
}
export default AllProject;
