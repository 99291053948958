import './Schedule.css';
import { RiCalendarScheduleLine } from 'react-icons/ri';
function Schedule() {
  return (
    <div className="header__schedule">
      <RiCalendarScheduleLine className="email__schedule" />
      <p className="header-schedule-item">6:00-22:00/7</p>
    </div>
  );
}
export default Schedule;
