import { useEffect, useLayoutEffect, useState } from 'react';
import MyPhone from '../../UI/MyPhone/MyPhone';
import LinkSocial from '../1_Header/SocialLinks/LinkSocial';

import './Footer.css';

import MyEmail from '../../UI/MyEmail/MyEmail';
import Schedule from '../1_Header/Schedule/Schedule';
import Menu from '../../UI/Menu/Menu';

function FooterMenu() {
  const [isScrollUp, setScrollUp] = useState(true);

  useEffect(() => {
    if (isScrollUp) {
      window.scroll({ top: 0, behavior: 'smooth' });
      setScrollUp(false);
    }
  }, [isScrollUp]);

  return (
    <Menu
      classNameNav="footer-menu"
      classNameUl="footer__menu__list"
      classNameLi="footer__menu__item"
      onClick={() => setScrollUp(true)}
      className={({ isActive }) =>
        isActive ? 'footer__menu__link footer-active' : 'footer__menu__link'
      }
    />
  );
}

function Footer() {
  const [isHeader, setIsHeader] = useState(false);

  useLayoutEffect(() => {
    if (window.innerWidth < 600) {
      setIsHeader(true);
    } else {
      setIsHeader(false);
    }
  }, [setIsHeader]);
  return (
    <>
      <div className="footer">
        <div className="container footer__container">
          <div className="footer__main">
            <div className="footer__menu">
              <FooterMenu />
            </div>

            {isHeader ? (
              <>
                <div className="footer__social-links">
                  <LinkSocial close={''} />
                  <div className="footer-phone header__info">
                    <MyPhone classphonelink="footer__phone-link " />
                    <MyEmail></MyEmail>
                    <Schedule />
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="footer__title_phone">
                  <div className="footer-phone header__info">
                    <MyPhone classphonelink="footer__phone-link " />
                    <MyEmail></MyEmail>
                    <Schedule />
                  </div>
                </div>
                <div className="footer__social-links">
                  <LinkSocial close={''} />
                </div>
              </>
            )}
          </div>
        </div>
        <div className="footer__down">
          <h3> Nettoyage des meubles | PH Service | 2024 </h3>
        </div>
      </div>
    </>
  );
}
export default Footer;
