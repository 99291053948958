import Header from '../../1_Header/Container/Header';
import Footer from '../../3_Footer/Footer';
import './Articles.css';
import quilon from '../../../img/articles/article2.jpg';
import quilon2 from '../../../img/articles/article2.2.jpg';
import { Cataloge } from './1_Article';
import WhatsApp from '../../WhatsUp/WhatsappLink';

function Quilon() {
  return (
    <>
      <div className="containerarticel">
        <div className="atricelandcatalog">
          <div className="articelquilon">
            <h2>Lavage de matelas Lyon</h2>

            <p>
              Le matelas est l'un des éléments les plus importants de notre
              chambre et en même temps l'endroit où nous passons environ un
              tiers de notre vie. Il est donc important que notre matelas soit
              propre et hygiénique. Laver régulièrement vos matelas est crucial
              pour maintenir la propreté et l’hygiène, et dans cet article nous
              présenterons quelques raisons pour lesquelles c’est si important.
            </p>
            <p>
              Premièrement, le lavage des matelas élimine les contaminants tels
              que la poussière, les acariens, les moisissures, les bactéries et
              les virus qui s’accumulent dans le matelas au fil du temps. Ces
              polluants peuvent provoquer des allergies, de l’asthme et d’autres
              maladies, et affecter également la qualité du sommeil. Il est donc
              important de laver régulièrement votre matelas pour éliminer ces
              contaminants nocifs.
            </p>
            <img className="quilon" src={quilon} title="quilon" alt="quilon" />
            <p>
              Deuxièmement, le lavage des matelas affecte l’esthétique et la
              fonctionnalité de notre matelas. Un matelas propre semble plus
              esthétique et contribue à un meilleur bien-être dans la chambre.
              De plus, laver les matelas peut également améliorer le confort de
              notre sommeil en éliminant les taches, les odeurs et autres
              saletés pouvant affecter la qualité du sommeil.
            </p>
            <p>
              Troisièmement, laver votre matelas peut prolonger sa durée de vie.
              Les matelas sont des investissements coûteux, il est donc
              important de veiller à leur durabilité. Un lavage régulier peut
              aider à garder votre matelas propre et hygiénique, ainsi qu’à
              prévenir l’usure. Grâce à cela, nous pourrons profiter plus
              longtemps de notre matelas.
            </p>
            <img
              className="quilon2"
              src={quilon2}
              title="quilon"
              alt="quilon"
            />
            <p>
              Quatrièmement, laver vos matelas peut aider à lutter contre les
              odeurs désagréables. Les matelas accumulent souvent des odeurs de
              sueur, de nourriture et d’autres sources qui peuvent être
              difficiles à éliminer. Le lavage des matelas élimine ces odeurs,
              ce qui affecte positivement le confort du sommeil et la qualité de
              l'air dans notre chambre.
            </p>
            <p>
              En résumé, le lavage des matelas est extrêmement important et doit
              être effectué régulièrement. En lavant régulièrement, nous
              assurons la propreté et l'hygiène de notre matelas, améliorons le
              confort du sommeil, prolongeons la durée de vie de notre matelas
              et prévenons le développement de polluants nocifs. Laver les
              matelas est une solution simple et efficace qui nous permettra de
              profiter longtemps de notre matelas.
            </p>
          </div>
          <Cataloge />
        </div>
      </div>
    </>
  );
}

function Article2() {
  const obj = {
    mainLang: 'PL',
    path: '/en/article1',
    langP: 'Poland',
    langEng: 'English',
  };
  return (
    <div className="body_wrapper">
      <Header obj={obj} />

      <Quilon />
      <WhatsApp />
      <Footer />
    </div>
  );
}
export default Article2;
