import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/effect-cube';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { EffectCube, Navigation } from 'swiper/modules';
import foto1 from '../../img/articles/article1.png';

import foto3 from '../../img/articles/diwanow2.png';
import './Swiper_article.css';

function DetailArticle(props) {
  const { path } = props;
  return (
    <div className="check">
      <Link to={path}>En détails...</Link>
    </div>
  );
}

function Article1() {
  return (
    <div className="block_1">
      <div className="block-article">
        <h3>Lavage des tissus d'ameublement</h3>
      </div>
      <div>
        <img src={foto1} alt="foto1" className="fotoImg fotoImg1" />
        <p className="special-article">
          Le lavage des meubles rembourrés est l'une des procédures d'hygiène
          les plus importantes à la maison et au travail. Les meubles rembourrés
          ne sont pas seulement un lieu de détente, mais souvent aussi un lieu
          de travail. comme surface de repas. Ils accumulent donc beaucoup de
          saletés, de bactéries et d'acariens qui peuvent avoir un impact
          négatif sur notre santé...
        </p>
      </div>
      <DetailArticle path="/article3" />
    </div>
  );
}

function Article3() {
  return (
    <div className="block_3">
      <div className="block-article">
        <h3>Laver les tapis et moquettes</h3>
      </div>
      <div>
        <img src={foto3} alt="foto3" className="fotoImg3 fotoImg" />
        <p className="special-article">
          Le lavage des tapis et moquettes est une activité importante qui
          affecte la propreté et l'hygiène de nos maisons. Malgré un passage
          régulier de l'aspirateur, divers types de saletés peuvent s'accumuler
          sur ces surfaces, ce qui nuit non seulement à l'esthétique, mais aussi
          à notre santé...
        </p>
      </div>
      <DetailArticle path="/article2" />
    </div>
  );
}
export default function SwiperArticle() {
  return (
    <>
      <Swiper
        loop={true}
        effect={'cube'}
        grabCursor={true}
        cubeEffect={{
          shadow: true,
          slideShadows: true,
          shadowOffset: 20,
          shadowScale: 0.94,
        }}
        navigation={true}
        modules={[EffectCube, Navigation]}
        className="swiper__article"
      >
        <SwiperSlide>
          <Article3 />
        </SwiperSlide>
        <SwiperSlide>
          <Article1 />
        </SwiperSlide>
      </Swiper>
    </>
  );
}
