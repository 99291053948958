// import SwiperArticles from '../../Swiper/Swiper';
import SwiperArticle from '../../Swiper/SwiperArticle';
import './Article.css';

function Articles() {
  return (
    <>
      <div className="main__articles">
        <div className="main-title-article">
          <p>Articles recommandés</p>
          <h2>
            Tout sur le nettoyage à sec des tapis, canapés, fauteuils, voitures,
            matelas, poussettes, canapés d'angle...
          </h2>
        </div>
        <SwiperArticle />
      </div>
    </>
  );
}
export default Articles;
