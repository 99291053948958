import Header from '../../1_Header/Container/Header';
import Footer from '../../3_Footer/Footer';
import SwiperUslugi from './SwiperUslugi8';
import UslugiList from '../../../UI/ListUslugi/ListUslugi';
import './Uslugi.css';

function UslugiImg() {
  return (
    <div className="mySwiper-uslugi">
      <SwiperUslugi />
    </div>
  );
}

function UslugiText() {
  return (
    <>
      <div className="uslugi__text">
        <h2>Laver les tapis et moquettes à Lyon</h2>
        <p>
          Le nettoyage à sec des tapis et moquettes est un processus important
          qui permet de garder votre maison propre et hygiénique. Au fil du
          temps, la poussière, la saleté, les poils d'animaux et autres
          contaminants s'accumulent dans les fibres et ne peuvent pas toujours
          être éliminés par un nettoyage quotidien. Un nettoyage professionnel
          élimine efficacement ces particules, améliorant ainsi la qualité de
          l’air intérieur. Les tapis nécessitent un nettoyage périodique car ils
          accumulent de la poussière, de la saleté, des poils d'animaux et des
          squames pendant leur utilisation. PH Service facilite ce processus en
          proposant des services de nettoyage de tapis sur place. Cela signifie
          que vous n'aurez pas besoin de prendre un grand tapis pour vous laver,
          le transporter sur le palier, etc.
        </p>
        <h3>Nettoyage à sec de tapis de toute complexité:</h3>
        <ul>
          <li>
            Les propriétaires de tels revêtements sont confrontés à divers types
            de contamination. Il peut s'agir de taches de nourriture et de
            boissons, d'urine d'animaux ou de saletés de chaussures. Il est sage
            de nettoyer tous les quelques mois pour réduire le nombre d'acariens
            et minimiser le risque d'allergies chez les enfants et les adultes.
          </li>
          <li>
            Le nettoyage à sec des tapis sur place ne fait pas perdre de temps
            au client. Le processus de trempage, de traitement de surface,
            d'aspiration de la saleté et de lavage final ne prend pas beaucoup
            de temps. Cela peut être fait à tout moment de l’année et par tous
            les temps.
          </li>
        </ul>
        <p>
          Le nettoyage à sec régulier des tapis prolonge non seulement leur
          durée de vie, mais contribue également à maintenir l'aspect esthétique
          de l'intérieur. Les tapis nettoyés ont un aspect frais, conservent
          leur intensité de couleur et créent une atmosphère chaleureuse dans
          votre maison ou votre bureau.
        </p>
      </div>
    </>
  );
}

function UslugiImgList() {
  return (
    <div className="uslugi">
      <div className="container">
        <div className="wrapper__uslugi">
          <div className="wrapper__img-text">
            <UslugiImg />
            <UslugiText />
          </div>
          <UslugiList />
        </div>
      </div>
    </div>
  );
}
function Usluga8() {
  const obj = {
    mainLang: 'PL',
    path: '/en/usluga8',
    langP: 'Poland',
    langEng: 'English',
  };
  return (
    <>
      <div className="body_wrapper">
        <Header obj={obj} />
        <UslugiImgList />
        <Footer />
      </div>
    </>
  );
}

export default Usluga8;
