import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

import Header from '../../1_Header/Container/Header';
import Footer from '../../3_Footer/Footer';
import './Articles.css';
import quilon from '../../../img/articles/foto13.jpg';
import quilon2 from '../../../img/articles/article1.2.jpg';
import WhatsApp from '../../WhatsUp/WhatsappLink';
import { useProject } from '../../../tools/ProviderContext';

export function Cataloge() {
  const [isScroll, setScroll] = useState(true);
  const { notScrollLineUp } = useProject();

  useEffect(() => {
    if (isScroll) {
      window.scroll({ top: -100, behavior: 'smooth' });
      setScroll(false);
    }
  }, [isScroll]);

  const toggle = () => {
    setScroll(true);
  };

  return (
    <div className="catalogs">
      <h3>Catalogue</h3>
      <ul>
        <li
          onClick={() => {
            toggle();
            notScrollLineUp();
          }}
        >
          <NavLink
            className={({ isActive }) => (isActive ? 'activelist' : '')}
            to="/article1"
          >
            <span>Laver les matelas</span>
          </NavLink>
        </li>
        <li
          onClick={() => {
            toggle();
            notScrollLineUp();
          }}
        >
          <NavLink
            className={({ isActive }) => (isActive ? 'activelist' : '')}
            to="/article2"
          >
            <span>Laver les tapis et moquettes</span>
          </NavLink>
        </li>
        <li
          onClick={() => {
            toggle();
            notScrollLineUp();
          }}
        >
          <NavLink
            className={({ isActive }) => (isActive ? 'activelist' : '')}
            to="/article3"
          >
            Lavage des tissus d'ameublement
          </NavLink>
        </li>
        <li
          onClick={() => {
            toggle();
            notScrollLineUp();
          }}
        >
          <NavLink
            className={({ isActive }) => (isActive ? 'activelist' : '')}
            to="/article4"
          >
            Lavage des rideaux, rideaux et stores
          </NavLink>
        </li>
        <li
          onClick={() => {
            toggle();
            notScrollLineUp();
          }}
        >
          <NavLink
            className={({ isActive }) => (isActive ? 'activelist' : '')}
            to="/article5"
          >
            Laver la sellerie d'une voiture
          </NavLink>
        </li>
      </ul>
    </div>
  );
}

function Quilon() {
  return (
    <>
      <div className="containerarticel">
        <div className="atricelandcatalog">
          <div className="articelquilon">
            <h2>Lavage des tissus d’ameublement Lyon</h2>

            <p>
              Laver les meubles rembourrés est l’une des activités d’hygiène les
              plus importantes dans nos maisons et sur nos lieux de travail. Les
              meubles rembourrés ne sont pas seulement un endroit pour se
              détendre, mais servent souvent aussi de coin travail ou de salle à
              manger. Ils accumulent donc beaucoup de saletés, de bactéries et
              d’acariens qui peuvent avoir un impact négatif sur notre santé.
            </p>
            <p>
              L’une des raisons les plus importantes de laver régulièrement les
              meubles rembourrés est d’en éliminer la saleté. Les meubles
              rembourrés, surtout de couleurs claires, commencent rapidement à
              paraître sales et endommagés. Les taches telles que les taches de
              nourriture, les taches d'huile ou les restes d'aliments séchés
              sont très difficiles à éliminer de la surface du rembourrage. Un
              lavage régulier permettra d’éliminer ces taches et de redonner au
              meuble son aspect d’origine.
            </p>
            <img className="quilon" src={quilon} title="quilon" alt="quilon" />
            <p>
              Une autre raison importante de laver les meubles rembourrés est
              d’empêcher la croissance de bactéries et de champignons. Si de la
              saleté et de l'humidité pénètrent à la surface des meubles
              rembourrés, des champignons et des bactéries peuvent se
              multiplier, ce qui peut provoquer des maladies et des allergies.
              Le lavage des meubles rembourrés aide à éliminer ces
              micro-organismes, ce qui a un effet positif sur notre santé.
            </p>
            <p>
              La troisième raison de laver les meubles rembourrés est de se
              débarrasser des acariens. Les acariens sont de petits organismes
              qui vivent dans la poussière et la saleté des surfaces des
              meubles. Ils provoquent des allergies chez de nombreuses
              personnes, notamment celles qui ont des problèmes respiratoires.
              Laver régulièrement les meubles rembourrés aidera à éliminer les
              acariens, ce qui améliorera la qualité de l’air dans nos maisons.
            </p>
            <img
              className="quilon2"
              src={quilon2}
              title="quilon"
              alt="quilon"
            />
            <p>
              En résumé, laver les meubles rembourrés est important pour
              maintenir l’hygiène dans nos maisons et sur nos lieux de travail.
              Il aide à éliminer la saleté, les bactéries et les acariens qui
              peuvent nuire à notre santé. Le lavage régulier des meubles
              rembourrés vous permet de conserver longtemps leur aspect et leur
              qualité.
            </p>
          </div>
          <Cataloge />
        </div>
      </div>
    </>
  );
}

function Article1() {
  const obj = {
    mainLang: 'PL',
    path: '/en/article3',
    langP: 'Poland',
    langEng: 'English',
  };
  return (
    <div className="body_wrapper">
      <Header obj={obj} />

      <Quilon />
      <WhatsApp />
      <Footer />
    </div>
  );
}
export default Article1;
