import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import './SwiperSlider.css';
import { useRef } from 'react';
import { useProject } from '../../tools/ProviderContext';

function ButtonSlider() {
  const { handleGetForm } = useProject();
  return (
    <button onClick={handleGetForm} className="slider__text-btn">
      Laisser une demande
    </button>
  );
}
function Garantia() {
  return (
    <span>
      GWARANTUJEMY ZWROT 100% OPLATY, JESLI KLIENT NIE BEDZIE ZADOWOLONY Z
      EFEKTU PRANIA !!!
    </span>
  );
}
function SliderOne() {
  const { isForma } = useProject();
  if (isForma) {
    document.body.style.overflow = 'hidden';
  } else {
    document.body.style.overflow = 'scroll';
  }

  return (
    <div className="slider">
      <div className="container">
        <div className="backdrop-sliders"></div>
        <div className="slider__text">
          <h2 className="slider__text-title">
            Lavage des tissus d'ameublement à Lyon
          </h2>
          <p className="slider__text-p">
            Entretien professionnel des meubles, détachage, élimination des
            odeurs
          </p>
          <ButtonSlider />
          <div className="slider__garantia">
            <Garantia />
          </div>
        </div>
      </div>
    </div>
  );
}
function SliderTwo() {
  const { isForma } = useProject();
  if (isForma) {
    document.body.style.overflow = 'hidden';
  } else {
    document.body.style.overflow = 'scroll';
  }

  return (
    <div className="sliderCar">
      <div className="container">
        <div className="backdrop-sliders"></div>
        <div className="slider__text">
          <h2 className="slider__text-title">
            Détaillant, lavage des garnitures de voiture
          </h2>
          <p className="slider__text-p">
            Nous donnerons une seconde vie à l'intérieur de votre voiture!
          </p>
          <ButtonSlider />
          <div className="slider__garantia">
            <Garantia />
          </div>
        </div>
      </div>
    </div>
  );
}
function SliderThree() {
  const { isForma } = useProject();
  if (isForma) {
    document.body.style.overflow = 'hidden';
  } else {
    document.body.style.overflow = 'scroll';
  }

  return (
    <div className=" sliderKids">
      <div className="container">
        <div className="backdrop-sliders"></div>
        <div className="slider__text">
          <h2 className="slider__text-title">
            Laver les poussettes et les sièges auto
          </h2>
          <p className="slider__text-p">
            Prenez soin de la santé de votre enfant!
          </p>
          <ButtonSlider />
          <div className="slider__garantia">
            <Garantia />
          </div>
        </div>
      </div>
    </div>
  );
}
function Slider4() {
  const { isForma } = useProject();
  if (isForma) {
    document.body.style.overflow = 'hidden';
  } else {
    document.body.style.overflow = 'scroll';
  }

  return (
    <div className=" sliderMatress">
      <div className="container">
        <div className="backdrop-sliders"></div>
        <div className="slider__text">
          <h2 className="slider__text-title">Laver les matelas</h2>
          <p className="slider__text-p">
            Nous éliminerons les contaminants tels que la poussière, les
            acariens, les moisissures et les bactéries qui s’accumulent dans le
            matelas au fil du temps.
          </p>
          <ButtonSlider />
          <div className="slider__garantia">
            <Garantia />
          </div>
        </div>
      </div>
    </div>
  );
}
function Slider5() {
  const { isForma } = useProject();
  if (isForma) {
    document.body.style.overflow = 'hidden';
  } else {
    document.body.style.overflow = 'scroll';
  }

  return (
    <div className="sliderRug">
      <div className="container">
        <div className="backdrop-sliders"></div>
        <div className="slider__text">
          <h2 className="slider__text-title">Laver les tapis et moquettes</h2>
          <p className="slider__text-p">
            Élimination de la poussière, de la saleté, des poils et des poils
            d'animaux.
          </p>
          <ButtonSlider />
          <div className="slider__garantia">
            <Garantia />
          </div>
        </div>
      </div>
    </div>
  );
}

function SwiperSliders() {
  const progressCircle = useRef(null);
  const progressContent = useRef(null);
  const onAutoplayTimeLeft = (s, time, progress) => {
    progressCircle.current.style.setProperty('--progress', 1 - progress);
    progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
  };
  return (
    <Swiper
      loop={true}
      spaceBetween={0}
      centeredSlides={true}
      slidesPerView={'auto'}
      autoplay={{
        delay: 5500,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      }}
      modules={[EffectCoverflow, Autoplay]}
      onAutoplayTimeLeft={onAutoplayTimeLeft}
      className="mySwiper"
    >
      <SwiperSlide>
        <SliderOne />
      </SwiperSlide>
      <SwiperSlide>
        <SliderTwo />
      </SwiperSlide>
      <SwiperSlide>
        <SliderThree />
      </SwiperSlide>
      <SwiperSlide>
        <Slider4 />
      </SwiperSlide>
      <SwiperSlide>
        <Slider5 />
      </SwiperSlide>
      <div className="autoplay-progress" slot="container-end">
        <svg viewBox="0 0 48 48" ref={progressCircle}>
          <circle cx="24" cy="24" r="20"></circle>
        </svg>
        <span ref={progressContent}></span>
      </div>
    </Swiper>
  );
}
export default SwiperSliders;
