import Header from '../../1_Header/Container/Header';
import Footer from '../../3_Footer/Footer';
import './Articles.css';
import quilon from '../../../img/articles/article4.jpg';
import quilon2 from '../../../img/articles/article4.4.jpg';
import { Cataloge } from './1_Article';
import WhatsApp from '../../WhatsUp/WhatsappLink';

function Quilon() {
  return (
    <>
      <div className="containerarticel">
        <div className="atricelandcatalog">
          <div className="articelquilon">
            <h2>Lavage des rideaux, rideaux et stores Lyon</h2>
            <p>
              Laver les rideaux et les stores est une activité souvent négligée
              pour garder nos maisons propres. Il s’agit cependant d’une
              activité très importante que nous ne devons pas ignorer. Le lavage
              de ces éléments d’équipement de fenêtre n’est pas seulement une
              question d’esthétique, mais avant tout de santé. Vous trouverez
              ci-dessous quelques raisons pour lesquelles le lavage des rideaux
              et des stores est si important.
            </p>
            <p>
              Tout d’abord, les rideaux, voilages et stores accumulent beaucoup
              de poussière, de pollen et d’autres polluants pouvant causer des
              problèmes respiratoires. De nombreuses personnes souffrent
              d’allergies et d’asthme, et ces polluants peuvent nuire à leur
              santé. Laver régulièrement ces articles peut contribuer à éliminer
              les polluants et à améliorer la qualité de l’air dans nos maisons.
            </p>
            <img className="quilon" src={quilon} title="quilon" alt="quilon" />
            <p>
              Deuxièmement, les rideaux, rideaux et stores sont exposés à la
              pollution de l’environnement. Ceux-ci peuvent inclure, entre
              autres: les gaz d'échappement, la fumée de cigarette ou les odeurs
              de cuisine. Si nous ne lavons pas ces éléments régulièrement, ces
              odeurs resteront sur les rideaux, rideaux et stores, ce qui pourra
              nuire à la qualité de l'air de nos maisons.
            </p>
            <p>
              Troisièmement, laver les rideaux et les stores peut aider à
              conserver leur aspect d’origine. Ces meubles de fenêtre sont
              souvent exposés au soleil, ce qui peut décolorer les couleurs et
              endommager les tissus. Les laver régulièrement peut aider à
              conserver leurs couleurs et leurs textures plus longtemps.
            </p>
            <img
              className="quilon2"
              src={quilon2}
              title="quilon"
              alt="quilon"
            />
            <p>
              Il convient également de noter que laver les rideaux et les stores
              est bénéfique non seulement pour notre santé, mais aussi pour
              notre portefeuille. Si nous lavons ces éléments régulièrement, ils
              seront plus durables et nous n'aurons pas à les remplacer si
              souvent par des neufs.
            </p>
            <p>
              En résumé, laver les rideaux et les stores est une activité
              importante à ne pas négliger pour garder notre maison propre. Il
              contribue à éliminer les polluants, à améliorer la qualité de
              l’air et à conserver l’aspect d’origine des tissus. Laver
              régulièrement ces articles est bénéfique tant pour notre santé que
              pour notre portefeuille.
            </p>
          </div>
          <Cataloge />
        </div>
      </div>
    </>
  );
}

function Article4() {
  const obj = {
    mainLang: 'PL',
    path: '/en/article4',
    langP: 'Poland',
    langEng: 'English',
  };
  return (
    <div className="body_wrapper">
      <Header obj={obj} />
      <WhatsApp />

      <Quilon />
      <Footer />
    </div>
  );
}
export default Article4;
