import Header from '../../1_Header/Container/Header';
import Services from '../../2_Main/Services/Services';
import Footer from '../../3_Footer/Footer';

function Usluga() {
  const obj = {
    mainLang: 'PL',
    path: '/en/usluga',
    langP: 'Poland',
    langEng: 'English',
  };
  return (
    <>
      <div className="body_wrapper">
        <Header obj={obj} />
        <Services />
        <Footer />
      </div>
    </>
  );
}

export default Usluga;
