import { NavLink } from 'react-router-dom';

import './Menu.css';

function Menu(props) {
  const {
    classNameNav = 'menu',
    classNameUl = 'menu__list',
    classNameLi = 'menu__item',
  } = props;
  return (
    <>
      <nav className={classNameNav}>
        <ul className={classNameUl}>
          <li className={classNameLi}>
            <NavLink to="/" {...props}>
              Principal
            </NavLink>
          </li>
          <li className={classNameLi}>
            <NavLink to="/about" {...props}>
              À propos de nous
            </NavLink>
          </li>
          <li className={classNameLi}>
            <NavLink to="/usluga" {...props}>
              Services
            </NavLink>
          </li>
          <li className={classNameLi}>
            <NavLink to="/price" {...props}>
              Prix
            </NavLink>
          </li>

          <li className={classNameLi}>
            <NavLink to="/article1" {...props}>
              Bloguer
            </NavLink>
          </li>
          <li className={classNameLi}>
            <NavLink to="/contact" {...props}>
              Contact
            </NavLink>
          </li>
        </ul>
      </nav>
    </>
  );
}

export default Menu;
