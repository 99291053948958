import Header from '../../1_Header/Container/Header';
import Footer from '../../3_Footer/Footer';
import SwiperUslugi from './SwiperUslugi7';
import UslugiList from '../../../UI/ListUslugi/ListUslugi';
import './Uslugi.css';

function UslugiImg() {
  return (
    <div className="mySwiper-uslugi">
      <SwiperUslugi />
    </div>
  );
}

function UslugiText() {
  return (
    <>
      <div className="uslugi__text">
        <h2> Nettoyage de sellerie cuir à Lyon</h2>
        <p>
          Le cuir naturel est un matériau coûteux qui doit être manipulé avec
          beaucoup de précautions. Malgré sa durabilité, il est sujet à des
          contaminations périodiques, et des microfissures et rayures peuvent
          également apparaître en surface.
        </p>
        <h3>Étapes du nettoyage complet des meubles en cuir:</h3>
        <ul>
          <li>Effectuer des diagnostics et vérifier l'état du matériel;</li>
          <li>
            Enlevez la poussière, la saleté et la plaque dentaire avec des
            produits de nettoyage spéciaux;
          </li>
          <li>
            Utilisez des détachants pour nettoyer le matériau de la saleté et
            des taches;
          </li>
          <li>
            Utiliser un produit nettoyant pour restaurer la couleur et l'odeur
            d'origine du matériau;
          </li>
          <li>
            La rénovation des meubles en cuir se fait à l'aide de produits
            spéciaux et délicats.
          </li>
        </ul>
        <p>
          Grâce à un travail aussi complet à la maison, les meubles en cuir
          retrouveront leur aspect, leur arôme et leur propreté d'origine.
        </p>
      </div>
    </>
  );
}

function UslugiImgList() {
  return (
    <div className="uslugi">
      <div className="container">
        <div className="wrapper__uslugi">
          <div className="wrapper__img-text">
            <UslugiImg />
            <UslugiText />
          </div>
          <UslugiList />
        </div>
      </div>
    </div>
  );
}
function Usluga7() {
  const obj = {
    mainLang: 'PL',
    path: '/en/usluga7',
    langP: 'Poland',
    langEng: 'English',
  };
  return (
    <>
      <div className="body_wrapper">
        <Header obj={obj} />
        <UslugiImgList />
        <Footer />
      </div>
    </>
  );
}

export default Usluga7;
