import Header from '../../1_Header/Container/Header';
import Footer from '../../3_Footer/Footer';
import SwiperUslugi from './SwiperUslugi6';
import UslugiList from '../../../UI/ListUslugi/ListUslugi';
import './Uslugi.css';

function UslugiImg() {
  return (
    <div className="mySwiper-uslugi">
      <SwiperUslugi />
    </div>
  );
}

function UslugiText() {
  return (
    <>
      <div className="uslugi__text">
        <h2> Nettoyage à sec de poussettes et sièges auto à Lyon</h2>
        <p>
          L’un des services les plus appréciés est le nettoyage à sec de
          poussettes et de sièges auto à Lyon. Nous comprenons qu'il est
          important pour vous de garder votre enfant propre et en sécurité,
          c'est pourquoi nous utilisons les dernières technologies et des
          recettes sûres pour obtenir d'excellents résultats.
        </p>
        <h3>Nos avantages:</h3>
        <ul>
          <li>
            L'innocuité passe avant tout : nous garantissons que tous les
            produits chimiques et méthodes que nous utilisons sont totalement
            inoffensifs. Nos artisans possèdent une vaste expérience dans le
            travail avec des produits pour enfants et comprennent à quel point
            il est nécessaire d'éviter les effets nocifs sur la santé des
            enfants. Nous utilisons des détergents neutres et un générateur de
            vapeur qui tue principalement les bactéries et micro-organismes sans
            utiliser de produits chimiques.
          </li>
          <li>
            élimination efficace des taches et des odeurs : Nos spécialistes
            sont équipés d'équipements modernes et de produits professionnels
            qui éliminent efficacement même les taches et les odeurs
            désagréables les plus difficiles. L'ensemble du processus de
            nettoyage est effectué manuellement. Il ne s'agit pas d'un lavage de
            poussettes anodin dans un lave-auto utilisant Karcher, mais d'un
            travail physique fastidieux. Grâce au nettoyage, votre poussette ou
            votre siège auto sera non seulement impeccablement propre, mais
            sentira également bon.
          </li>
          <li>
            Conservation des objets à long terme : la technologie de
            transformation garantit non seulement la fraîcheur des produits,
            mais prolonge également leur durée de vie. Pendant le processus de
            nettoyage, le produit est complètement démonté. Nous abordons chaque
            détail avec soin, en prêtant attention à chaque pli et couture pour
            conserver des couleurs éclatantes, prévenir l’usure et maintenir des
            normes d’hygiène optimales. Le cadre, les roues et tous les éléments
            en plastique du châssis sont soigneusement lavés. L'assemblage est
            effectué strictement selon les instructions des fabricants pour
            éviter d'éventuelles situations traumatisantes pendant le
            fonctionnement.
          </li>
          <li>
            Équipe professionnelle : nos experts en nettoyage de sièges d'auto
            possèdent des compétences approfondies dans le travail avec des
            produits pour bébés. Des centaines de modèles des plus grands
            fabricants mondiaux sont passés entre nos mains, tels que Cybex,
            Bair, Anex, Espiro, Carrello, El Camino, KinderKraft, Lionelo, etc.
            Nous connaissons toutes les subtilités de l'analyse et les
            spécificités du nettoyage de chaque matériau. Nous prêtons attention
            aux détails, nous effectuons notre travail consciencieusement et
            nous sommes prêts à offrir à votre tout-petit un maximum de confort
            et de propreté.
          </li>
        </ul>
        <p>
          N'oubliez pas que le nettoyage à sec des sièges auto et des poussettes
          concerne avant tout la santé de votre enfant.
        </p>
      </div>
    </>
  );
}

function UslugiImgList() {
  return (
    <div className="uslugi">
      <div className="container">
        <div className="wrapper__uslugi">
          <div className="wrapper__img-text">
            <UslugiImg />
            <UslugiText />
          </div>
          <UslugiList />
        </div>
      </div>
    </div>
  );
}
function Usluga6() {
  const obj = {
    mainLang: 'PL',
    path: '/en/usluga6',
    langP: 'Poland',
    langEng: 'English',
  };
  return (
    <>
      <div className="body_wrapper">
        <Header obj={obj} />
        <UslugiImgList />
        <Footer />
      </div>
    </>
  );
}

export default Usluga6;
