import './Important.css';
import expert from '../../../img/important/expert.png';
import quality from '../../../img/important/quality-control.png';
import warning from '../../../img/important/profile.png';
import burning from '../../../img/important/burning.png';

function Important() {
  return (
    <div className="main__important">
      <h2 className="main-important-h2">
        <span>Pourquoi nous!</span>
      </h2>

      <div className="main-important-blocks">
        <div className="main-important-block">
          <img src={expert} alt="expert" className="expert" />
          <h3>Professionnalisme</h3>
          <p>
            Nous abordons la mise en œuvre des tâches de manière responsable et
            garantissons une coopération efficace avec nos clients et
            partenaires.
          </p>
        </div>

        <div className="main-important-block">
          <img src={warning} alt="expert" className="expert" />
          <h3>Approche individuelle</h3>
          <p>
            Nous proposons une approche individuelle à chaque client, analysant
            soigneusement ses besoins et proposant des solutions optimales pour
            répondre pleinement à ses attentes.
          </p>
        </div>

        <div className="main-important-block">
          <img src={quality} alt="expert" className="expert" />
          <h3>Qualité</h3>
          <p>
            Tous les produits chimiques que nous utilisons sont totalement
            inoffensifs pour l'homme et les animaux, disposent de tous les
            certificats de conformité nécessaires et nos équipements
            professionnels de grandes marques telles que Karcher (Allemagne)
            garantissent un travail de haute qualité.
          </p>
        </div>
        <div className="main-important-block">
          <img src={burning} alt="expert" className="expert" />
          <h3>Des délais rapides</h3>
          <p>
            Nous viendrons laver le revêtement de vos meubles demain ou même
            aujourd'hui à un moment qui vous convient.
          </p>
        </div>
      </div>
    </div>
  );
}
export default Important;
