import Articles from '../Articles/Article';
import Services from '../Services/Services';
import Important from '../Important/Important';
import './Main.css';
import TitleGallary from '../Gallary/SWGallary';
// import SwiperVideoGallery from '../SwiperVideoGallery/SwiperVideoGallery';
// import FormaZayavkaContact from '../../../UI/Forma/Forma_zayavka';
import SliderCourusel from '../SliderCourusel/Slider';
import Question from '../Question/Question';

function Main() {
  return (
    <main className="main-first">
      <SliderCourusel />

      <div className="container main__important">
        <Important />
      </div>
      <div className="main__servise">
        <Services />
      </div>
      <Question />

      <TitleGallary />

      {/* <div className="container main__important2">
        <SwiperVideoGallery />
      </div> */}

      <Articles />

      {/* <div id="forma" className="container main-forma">
        <FormaZayavkaContact />
      </div> */}
    </main>
  );
}
export default Main;
