import Header from '../../1_Header/Container/Header';
import Footer from '../../3_Footer/Footer';
import LinkSocial from '../../1_Header/SocialLinks/LinkSocial';
import foto from '../../../img/contact/plan.jpg';

import './Contact.css';
import WhatsApp from '../../WhatsUp/WhatsappLink';
import MyPhone from '../../../UI/MyPhone/MyPhone';
import MyEmail from '../../../UI/MyEmail/MyEmail';
import FormaZayavkaContact from '../../../UI/Forma/Forma_zayavka';

function ContactTable() {
  return (
    <div className="contact">
      <div className="contacts-title">
        <h1>CONTACT</h1>
      </div>
      <div className="container contacts__container">
        <div className="contacts__item">
          <div className="contacts__item-phone">
            <p>Numéro de téléphone:</p>
            <MyPhone
              classphone="contac="
              icons=""
              classphonelink="phone-contact"
            />
          </div>
          <div className="contacts__item-phone">
            <p>E-mail:</p>
            <MyEmail icons="" classnamelink="email-contact"></MyEmail>
          </div>

          <div className="contacts__item-social">
            <p>Nous sommes sur les réseaux sociaux:</p>
            <div className="contacts__item-social-links">
              <LinkSocial />
            </div>
          </div>
          <div className="contacts__item-praca">
            <img src={foto} alt="foto" />
            <p>Nous travaillons 7 jours sur 7, de 06:00 à 22:00,</p>
            <p>et si la situation l'exige, également la nuit.</p>
            <p>Nous opérons à Lyon et dans ses environs.</p>
          </div>
          <div className="adress">Lyon, France,</div>
          <FormaZayavkaContact />
        </div>
      </div>
    </div>
  );
}
function Contact() {
  const obj = {
    mainLang: 'PL',
    path: '/en/contact',
    langP: 'Poland',
    langEng: 'English',
  };
  return (
    <div className="body_wrapper">
      <Header obj={obj} />

      <ContactTable />
      <WhatsApp />
      <Footer />
    </div>
  );
}
export default Contact;
