import { useFormik } from 'formik';
import { InputMask } from 'primereact/inputmask';
import './Forma_zayavka.css';
import { WhatsApp, Telegram } from './msg/Msg';

export default function FormaZayavkaContact(props) {
  const { formaTitle = 'Remplissez le formulaire et nous vous rappellerons' } =
    props;
  const validate = (values) => {
    const errors = {};

    if (!values.firstName) {
      errors.firstName = 'Le champ doit être rempli...';
    }
    if (!values.tel) {
      errors.tel = 'Le champ doit être rempli...';
    }

    return errors;
  };
  const formik = useFormik({
    initialValues: {
      firstName: '',
      tel: '',
      question: '',
    },
    validate,
    onSubmit: async (values) => {
      const d = new Date();
      const year = d.getFullYear();
      const month = d.getMonth() + 1;
      const day = d.getDate();
      const hour = d.getHours();
      const minute = d.getMinutes();
      const fullDate = `${day}/${month}/${year} ${hour}:${minute}`;

      const data = {
        name: values.firstName,
        tel: values.tel,
        question: values.question,
        msg: fullDate,
      };

      let response = await fetch('mail.php', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
        },
      });
      let result = await response.text();

      values.firstName = '';
      values.tel = '';
      alert(result);
    },
  });

  return (
    <form className="forma" onSubmit={formik.handleSubmit}>
      <h2 className="forma-service-h2" id="h2">
        {formaTitle}
      </h2>
      {formik.touched.firstName && formik.errors.firstName ? (
        <div
          style={{
            color: '#ffd2d2',
            marginLeft: '12%',
            paddingBottom: '5px',
          }}
        >
          {formik.errors.firstName}
        </div>
      ) : null}
      <input
        className="form__input-item"
        id="firstName"
        name="firstName"
        type="text"
        placeholder="Votre nom..."
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.firstName}
      />
      {formik.touched.tel && formik.errors.tel ? (
        <div
          style={{
            color: '#ffd2d2',
            marginLeft: '12%',
            paddingBottom: '5px',
          }}
        >
          {formik.errors.tel}
        </div>
      ) : null}

      <InputMask
        id="tel"
        name="tel"
        type="tel"
        mask="+33 999 999 999"
        placeholder="+33___ ___ ___"
        onBlur={formik.handleBlur}
        value={formik.values.tel}
        onChange={formik.handleChange}
      />
      {formik.touched.email && formik.errors.email ? (
        <div
          style={{
            color: '#ffd2d2',
            marginLeft: '12%',
            paddingBottom: '5px',
          }}
        >
          {formik.errors.email}
        </div>
      ) : null}

      <div className="form__btn">
        <input type="submit" className="btn" value="Commander un appel"></input>
      </div>

      <p className="personaldata">
        En soumettant votre candidature, vous acceptez la politique de
        traitement des données personnelles.
      </p>
      <p className="socialmsg">Możesz także napisać do nas w komunikatorach:</p>
      <div className="msgwtv">
        <WhatsApp />
        <Telegram />
      </div>
    </form>
  );
}
export function FormaZayavka(props) {
  const { closeForma } = props;

  const validate = (values) => {
    const errors = {};

    if (!values.firstName) {
      errors.firstName = 'Le champ doit être rempli...';
    }
    if (!values.tel) {
      errors.tel = 'Le champ doit être rempli...';
    }

    return errors;
  };
  const formik = useFormik({
    initialValues: {
      firstName: '',
      tel: '',
    },
    validate,
    onSubmit: async (values) => {
      const d = new Date();
      const year = d.getFullYear();
      const month = d.getMonth() + 1;
      const day = d.getDate();
      const hour = d.getHours();
      const minute = d.getMinutes();
      const fullDate = `${day}/${month}/${year} ${hour}:${minute}`;

      const data = {
        name: values.firstName,
        tel: values.tel,
        msg: fullDate,
      };

      let response = await fetch('mail.php', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
        },
      });
      let result = await response.text();

      values.firstName = '';
      values.tel = '';

      alert(result);
      closeForma();
    },
  });

  return (
    <form onClick={(e) => e.stopPropagation()} onSubmit={formik.handleSubmit}>
      <div onClick={closeForma} className="closeform">
        <span></span>
        <span></span>
      </div>
      <h2 id="h2">Wypełnij formularz, a my oddzwonimy do Ciebie</h2>
      {formik.touched.firstName && formik.errors.firstName ? (
        <div
          style={{
            color: '#ffd2d2',
            marginLeft: '12%',
            paddingBottom: '5px',
          }}
        >
          {formik.errors.firstName}
        </div>
      ) : null}
      <input
        className="form__input-item"
        id="firstName"
        name="firstName"
        type="text"
        placeholder="Votre nom..."
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.firstName}
      />
      {formik.touched.tel && formik.errors.tel ? (
        <div
          style={{
            color: '#ffd2d2',
            marginLeft: '12%',
            paddingBottom: '5px',
          }}
        >
          {formik.errors.tel}
        </div>
      ) : null}

      <InputMask
        id="tel"
        name="tel"
        type="tel"
        mask="+33 999 999 999"
        placeholder="+33___ ___ ___"
        onBlur={formik.handleBlur}
        value={formik.values.tel}
        onChange={formik.handleChange}
      />
      {formik.touched.email && formik.errors.email ? (
        <div
          style={{
            color: '#ffd2d2',
            marginLeft: '12%',
            paddingBottom: '5px',
          }}
        >
          {formik.errors.email}
        </div>
      ) : null}

      <div className="form__btn">
        <input type="submit" className="btn" value="Commander un appel"></input>
      </div>
      <p className="personaldata">
        En soumettant votre candidature, vous acceptez la politique de
        traitement des données personnelles.
      </p>
      <p className="socialmsg2">
        Vous pouvez également nous écrire dans les messageries:
      </p>
      <div className="msgwtv">
        <WhatsApp />
        <Telegram />
      </div>
    </form>
  );
}
