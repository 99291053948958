import Header from '../../1_Header/Container/Header';
import Footer from '../../3_Footer/Footer';
import SwiperUslugi from './SwiperUslugi4';
import UslugiList from '../../../UI/ListUslugi/ListUslugi';
import './Uslugi.css';

function UslugiImg() {
  return (
    <div className="mySwiper-uslugi">
      <SwiperUslugi />
    </div>
  );
}

function UslugiText() {
  return (
    <>
      <div className="uslugi__text">
        <h2> Nettoyage de matelas à Lyon</h2>
        <p>
          Peu importe combien nous essayons de garder les matelas propres, avec
          le temps, le matériau perd sa couleur d'origine et se salit. Personne
          n’est à l’abri de renverser du café, du thé ou du vin au lit. PH
          Service propose un nettoyage urgent du matelas de la saleté à l'aide
          de matériel professionnel et de produits écologiques de la série
          professionnelle.
        </p>
        <h3>Pourquoi le nettoyage à sec des matelas est-il nécessaire?</h3>
        <p>
          Pendant l'utilisation, la surface du matelas absorbe les particules de
          sueur, les liquides renversés, les odeurs de nourriture et bien plus
          encore. De plus, les micro-organismes, les acariens et les pellicules
          pénètrent dans le tissu. Tout cela nécessite un nettoyage périodique.
          Cependant, cela n’est pas possible avec des produits d’entretien
          ménager. Imaginez la quantité de sueur que le tissu du matelas absorbe
          (surtout pendant les étés chauds). Il est impossible de se protéger
          contre les poils, les poils d’animaux et surtout la poussière. Le
          nettoyage à sec du matelas est également obligatoire pour l'enfant
          afin d'éviter le développement d'une réaction allergique aux acariens.
        </p>
        <h3>
          Nettoyer les matelas à la maison permet de résoudre plusieurs
          problèmes à la fois:
        </h3>

        <ul>
          <li>Élimine les odeurs désagréables du matelas;</li>
          <li>Nettoyez-le des taches;</li>
          <li>
            Traitement des sièges, des revêtements de plafond et des tapis avec
            de la mousse à effet détachant et nettoyant en profondeur;
          </li>
          <li>
            Éliminer les micro-organismes, la poussière, les cheveux (à l'aide
            d'un aspirateur et d'un produit nettoyant spécial);
          </li>
        </ul>
        <p>
          Grâce au travail de l'équipe de nettoyage mobile PH Service, vous
          bénéficierez d'un sommeil sain et réparateur sur un matelas moelleux,
          propre et frais. En même temps, vous n'êtes pas obligé d'emporter les
          meubles n'importe où : nous ferons tout chez vous, rapidement et
          efficacement!
        </p>
      </div>
    </>
  );
}

function UslugiImgList() {
  return (
    <div className="uslugi">
      <div className="container">
        <div className="wrapper__uslugi">
          <div className="wrapper__img-text">
            <UslugiImg />
            <UslugiText />
          </div>
          <UslugiList />
        </div>
      </div>
    </div>
  );
}
function Usluga4() {
  const obj = {
    mainLang: 'PL',
    path: '/en/usluga4',
    langP: 'Poland',
    langEng: 'English',
  };
  return (
    <>
      <div className="body_wrapper">
        <Header obj={obj} />
        <UslugiImgList />
        <Footer />
      </div>
    </>
  );
}

export default Usluga4;
