import { useLayoutEffect } from 'react';
import { useProject } from '../../../tools/ProviderContext';
import { useState } from 'react';
import Menu from '../../../UI/Menu/Menu';
function Nav() {
  const { closeBurgerMenu, notScrollBtn, closeMenu } = useProject();
  const [isMenu800, setMenu800] = useState(false);
  useLayoutEffect(() => {
    if (window.innerWidth < 1070) {
      setMenu800(false);
    } else {
      setMenu800(true);
    }
  }, [isMenu800]);
  return (
    <>
      {isMenu800 ? (
        <Menu
          className="menu__link"
          onClick={() => {
            closeBurgerMenu();
          }}
        />
      ) : (
        <Menu
          className="menu__link"
          onClick={() => {
            closeBurgerMenu();
            notScrollBtn();
            closeMenu();
          }}
        />
      )}
    </>
  );
}
export default Nav;
