import { useState } from 'react';
import './Question.css';
import { IoChevronDownOutline } from 'react-icons/io5';

function Question() {
  const [isVisible, setVisible] = useState(true);
  const [isVisible2, setVisible2] = useState(true);
  const [isVisible3, setVisible3] = useState(true);
  const [isVisible4, setVisible4] = useState(true);
  const [isVisible5, setVisible5] = useState(true);
  const [isVisible6, setVisible6] = useState(true);
  const [isVisible7, setVisible7] = useState(true);
  const [isVisible8, setVisible8] = useState(true);
  const [isVisible9, setVisible9] = useState(true);
  const handleVector = () => {
    setVisible(!isVisible);
  };
  const handleVector2 = () => {
    setVisible2(!isVisible2);
  };
  const handleVector3 = () => {
    setVisible3(!isVisible3);
  };
  const handleVector4 = () => {
    setVisible4(!isVisible4);
  };
  const handleVector5 = () => {
    setVisible5(!isVisible5);
  };
  const handleVector6 = () => {
    setVisible6(!isVisible6);
  };
  const handleVector7 = () => {
    setVisible7(!isVisible7);
  };
  const handleVector8 = () => {
    setVisible8(!isVisible8);
  };
  const handleVector9 = () => {
    setVisible9(!isVisible9);
  };

  return (
    <>
      <div className="container">
        <div className="question">
          <div className="question__title">
            <span>Questions fréquemment posées</span>
          </div>
          <div className="question__items">
            <div className="question__item">
              <div onClick={handleVector} className="question__text">
                <span>Comment les meubles sont-ils nettoyés à sec?</span>
                <IoChevronDownOutline
                  onClick={handleVector}
                  className={
                    isVisible ? 'question__icon' : 'question__icon-none'
                  }
                />
              </div>
              <div className={isVisible ? 'response-none' : 'response'}>
                Le ménage s'effectue directement à votre domicile. Notre
                spécialiste arrive avec tout le matériel nécessaire. La seule
                chose dont il a besoin est d’avoir accès à une prise et à de
                l’eau (de préférence tiède). Ensuite, le produit est valorisé,
                le degré de contamination est évalué et les produits chimiques
                nécessaires à un nettoyage efficace sont sélectionnés. La
                première étape consiste à éliminer les taches difficiles avec
                tous types de détachants. Ensuite, le processus de trempage du
                produit avec une composition détergente spéciale pour un
                nettoyage en profondeur commence, pénétrant profondément dans la
                structure du produit. Un nettoyant très moussant est ensuite
                appliqué pour éliminer la saleté, la crasse et les taches du
                revêtement. Quelques minutes après l'application, une action
                mécanique est effectuée sur les endroits particulièrement sales
                avec des brosses de dureté variable. La composition détergente
                utilisée est ensuite collectée à l'aide d'un aspirateur et
                rincée abondamment à l'eau jusqu'à ce que tous les contaminants
                et produits chimiques restants soient complètement éliminés.
                Après nettoyage, le produit reste légèrement humide.
              </div>
            </div>
            <div className="question__item">
              <div onClick={handleVector2} className="question__text">
                <span>Combien coûte une visite chez un spécialiste?</span>
                <IoChevronDownOutline
                  onClick={handleVector2}
                  className={
                    isVisible2 ? 'question__icon' : 'question__icon-none'
                  }
                />
              </div>
              <div
                className={
                  isVisible2 ? 'response-none-small' : 'response-small'
                }
              >
                La visite d'un spécialiste en ville est gratuite. Vous paierez
                pour le nettoyage exactement le montant indiqué dans la liste de
                prix. Le déplacement du spécialiste en dehors de la ville doit
                être discuté individuellement.
              </div>
            </div>
            <div className="question__item">
              <div onClick={handleVector3} className="question__text">
                <span>
                  Garantissez-vous que toutes les taches seront éliminées ?
                </span>
                <IoChevronDownOutline
                  onClick={handleVector3}
                  className={
                    isVisible3 ? 'question__icon' : 'question__icon-none'
                  }
                />
              </div>
              <div className={isVisible3 ? 'response-none' : 'response'}>
                Malheureusement, nous ne pouvons pas garantir l'élimination à
                100 % de toutes les taches et odeurs désagréables, car cela
                dépend de nombreux facteurs : le type de saleté, sa quantité, le
                temps passé sur la surface, la température d'exposition et les
                tentatives d'auto-nettoyage. Certaines taches, comme du sang, de
                la graisse ou de la colle, peuvent persister et la source de
                l'odeur peut se trouver profondément dans le matériau.
                Cependant, nous nous efforçons toujours d’obtenir le meilleur
                résultat possible et faisons tout notre possible.
              </div>
            </div>
            <div className="question__item">
              <div onClick={handleVector4} className="question__text">
                <span>Combien de temps dure le nettoyage?</span>
                <IoChevronDownOutline
                  onClick={handleVector4}
                  className={
                    isVisible4 ? 'question__icon' : 'question__icon-none'
                  }
                />
              </div>
              <div
                className={
                  isVisible4 ? 'response-none-small' : 'response-small'
                }
              >
                Le processus de nettoyage à sec d'un canapé/matelas prend en
                moyenne environ 2 heures avec un niveau de saleté moyen. Tout
                dépend du matériau et de la structure du tissu, ainsi que du
                degré de saleté. Nous pouvons sécher les meubles au moment qui
                vous convient. Après nettoyage, le produit reste légèrement
                humide et nécessite un séchage. En hiver 10-12 heures. En été –
                6-8 heures. Vous pouvez accélérer le processus de séchage en
                nous laissant sécher vos meubles après le nettoyage. Le séchage
                prendra 2 heures.
              </div>
            </div>
            <div className="question__item">
              <div onClick={handleVector5} className="question__text">
                <span>Quand puis-je commencer à utiliser le produit ?</span>
                <IoChevronDownOutline
                  onClick={handleVector5}
                  className={
                    isVisible5 ? 'question__icon' : 'question__icon-none'
                  }
                />
              </div>
              <div className={isVisible5 ? 'response-none' : 'response'}>
                <p>
                  Le nettoyage est humide, il faut donc un certain temps pour
                  sécher après le nettoyage. En hiver, dans les
                  appartements/maisons bien chauffés, les tissus d'ameublement
                  sèchent généralement en moyenne 10 à 12 heures après le
                  nettoyage. En été – 6-8 heures. Il faut également comprendre
                  que selon le matériau, le séchage peut prendre plusieurs
                  heures de plus.
                </p>
                <p>
                  Cela s'applique particulièrement aux produits en coton
                  naturel. De plus, il faut environ une journée pour sécher tous
                  les types de matelas après le nettoyage.
                </p>
                <p>
                  Pour accélérer le processus de séchage, aérez simplement bien
                  la pièce. Si vous souhaitez utiliser le produit le même jour,
                  vous pouvez nous commander un séchage forcé et le séchage
                  complet prendra environ deux heures.
                </p>
              </div>
            </div>
            <div className="question__item">
              <div onClick={handleVector6} className="question__text">
                <span>
                  Quels produits chimiques et équipements utilisez-vous?
                </span>
                <IoChevronDownOutline
                  onClick={handleVector6}
                  className={
                    isVisible6 ? 'question__icon' : 'question__icon-none'
                  }
                />
              </div>
              <div className={isVisible6 ? 'response-none' : 'response'}>
                Nous disposons d’un choix assez large de tous types
                d’équipements et de produits chimiques pour différents types de
                matériaux. Principalement du fabricant allemand Karcher. Nous
                utilisons un aspirateur professionnel Karcher Puzzi 10/1. Les
                principaux produits chimiques sont le Karcher RM 760 et RM 764
                (un produit permettant de nettoyer les tapis textiles et les
                meubles rembourrés à l'aide d'un aspirateur). Il s'agit d'une
                substance chimique hypoallergénique professionnelle avec tous
                les certificats de conformité et totalement inoffensive pour
                l'homme et les animaux. Également une large gamme des meilleurs
                détachants des producteurs mondiaux Koch, Kiehl, Grass, Karpars,
                Chemspec.
              </div>
            </div>
            <div className="question__item">
              <div onClick={handleVector7} className="question__text">
                <span>
                  À quelle fréquence les meubles doivent-ils être nettoyés à
                  sec?
                </span>
                <IoChevronDownOutline
                  onClick={handleVector7}
                  className={
                    isVisible7 ? 'question__icon' : 'question__icon-none'
                  }
                />
              </div>
              <div className={isVisible7 ? 'response-none' : 'response'}>
                Il est recommandé de nettoyer à sec les meubles rembourrés et
                les tapis au maximum une fois tous les six mois/an. C'est durant
                cette période que les meubles accumulent la poussière, la
                saleté, les poils d'animaux et diverses taches apparaissent.
                Après tout, il ne se passe pas un jour sans que nous ne passions
                au moins 5 minutes sur notre canapé ou notre fauteuil préféré.
                Il est toujours préférable d'éliminer tous les « problèmes »
                sans tarder, avant qu'ils n'aient eu le temps d'être absorbés et
                séchés. Cela donne 95 % de chances qu'il ne reste aucune trace
                après le nettoyage. Il ne faut cependant pas abuser de la
                propreté. Un nettoyage trop fréquent peut entraîner une durée de
                vie réduite du produit, une perte d’apparence, une usure et une
                décoloration. Vous ne lavez pas votre T-shirt préféré plusieurs
                fois par jour, n'est-ce pas?
              </div>
            </div>
            <div className="question__item">
              <div onClick={handleVector8} className="question__text">
                <span>
                  Quelle période de l’année est la meilleure pour le nettoyage à
                  sec?
                </span>
                <IoChevronDownOutline
                  onClick={handleVector8}
                  className={
                    isVisible8 ? 'question__icon' : 'question__icon-none'
                  }
                />
              </div>
              <div
                className={
                  isVisible8 ? 'response-none-small' : 'response-small'
                }
              >
                Lorsqu’il s’agit de pressing de meubles, cela n’a rien à voir
                avec la saison. En hiver, dans les appartements/maisons bien
                chauffés, les tissus d'ameublement sèchent généralement en
                moyenne 10 à 12 heures après le nettoyage. En été – 6-8 heures.
                Lorsqu'il s'agit de nettoyage à sec de voitures, il est
                certainement préférable de le faire en été car le séchage de la
                voiture en hiver est extrêmement problématique. De plus, en
                hiver, l’intérieur de la voiture est exposé à une pollution
                maximale en raison des fortes précipitations.
              </div>
            </div>
            <div className="question__item">
              <div onClick={handleVector9} className="question__text">
                <span>
                  Quelles sont les étapes du nettoyage des meubles par
                  extraction ?
                </span>
                <IoChevronDownOutline
                  onClick={handleVector9}
                  className={
                    isVisible9 ? 'question__icon' : 'question__icon-none'
                  }
                />
              </div>
              <div className={isVisible9 ? 'response-none' : 'response'}>
                <p>
                  Le nettoyage par extraction humide des meubles comprend les
                  étapes suivantes:
                </p>
                <ul>
                  <li>
                    Évaluation du degré de salissure, identification des
                    matériaux, sélection des produits de nettoyage.
                  </li>
                  <li>
                    Pré-pulvériser avec un nettoyant pour meubles. Cette étape
                    sert à dissoudre les impuretés.
                  </li>
                  <li>
                    Brossage. Certains tissus nécessitent un brossage.
                    Attention, tous les tissus ne peuvent pas être brossés.
                  </li>
                  <li>
                    Nettoyage de base des tissus d'ameublement. Lors de cette
                    étape, la sellerie est rincée à l'aide d'une machine
                    d'extraction Karcher. Durant cette étape, la plupart des
                    impuretés sont éliminées.
                  </li>
                  <li>
                    La dernière étape consiste à rincer le tissu du meuble à
                    l'eau claire. Le but de cette étape est d’éliminer les
                    saletés et produits chimiques résiduels.
                  </li>
                  <li>
                    Séchage. Cela se produit naturellement et dure environ 10 à
                    15 heures.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Question;
