import './Services.css';
import main1 from '../../../img/Services/foto15.jpg';
import main2 from '../../../img/Services/car4.jpg';
import main3 from '../../../img/Services/armchair.png';
import main4 from '../../../img/Services/foto14.jpg';
import main5 from '../../../img/Services/cornerclean.png';
import main6 from '../../../img/Services/kids.png';
import main7 from '../../../img/Services/chair.jpg';
import main8 from '../../../img/Services/rug.jpg';
import main9 from '../../../img/Services/curtents.jpg';
import { Link } from 'react-router-dom';

function LinkMore(props) {
  const { path = '/' } = props;

  return (
    <Link {...props} to={path}>
      {' '}
      <span className="main-services-link">En détails...</span>
    </Link>
  );
}

function Services() {
  return (
    <>
      <div className="container container-main-service">
        <div className="main-title_uslugi">
          <p className="main-title_uslugi-p">Nos prestations</p>
          <div className="main-title_uslugi-line"></div>
          <h1>
            Lavage professionnel de canapés, fauteuils, matelas, poussettes,
            canapés d'angle, rideaux, stores, tapis, selleries automobiles,
            selleries meubles...
          </h1>
        </div>

        <div className="main__services-all">
          <div className="main__services-all-item">
            <img src={main1} alt="photo1" className="main__services-all-img" />
            <div className="main__services-all-text">
              <h2 className="main__services-all-title">Laver les canapés</h2>
              <LinkMore path={'/usluga1'} />
            </div>
          </div>
          <div className="main__services-all-item">
            <img src={main2} alt="photo1" className="main__services-all-img" />
            <div className="main__services-all-text">
              <h2 className="main__services-all-title">Sellerie automobile</h2>
              <LinkMore path={'/usluga2'} />
            </div>
          </div>
          <div className="main__services-all-item">
            <img src={main3} alt="photo1" className="main__services-all-img" />
            <div className="main__services-all-text">
              <h2 className="main__services-all-title">Laver les fauteuils</h2>
              <LinkMore path={'/usluga3'} />
            </div>
          </div>
          <div className="main__services-all-item">
            <img src={main4} alt="photo1" className="main__services-all-img" />
            <div className="main__services-all-text">
              <h2 className="main__services-all-title">Laver les matelas</h2>
              <LinkMore path={'/usluga4'} />
            </div>
          </div>
          <div className="main__services-all-item">
            <img src={main5} alt="photo1" className="main__services-all-img" />
            <div className="main__services-all-text">
              <h2 className="main__services-all-title">Lavage d'angle</h2>
              <LinkMore path={'/usluga5'} />
            </div>
          </div>
          <div className="main__services-all-item">
            <img src={main6} alt="photo1" className="main__services-all-img" />
            <div className="main__services-all-text">
              <h2 className="main__services-all-title">
                Nettoyage à sec de poussettes et sièges auto
              </h2>
              <LinkMore
                path={'/usluga6'}
                style={{ position: 'relative', top: '-20px' }}
              />
            </div>
          </div>
          <div className="main__services-all-item">
            <img src={main7} alt="photo1" className="main__services-all-img" />
            <div className="main__services-all-text">
              <h2 className="main__services-all-title">
                Nettoyer les revêtements en cuir
              </h2>
              <LinkMore
                path={'/usluga7'}
                style={{ position: 'relative', top: '-20px' }}
              />
            </div>
          </div>
          <div className="main__services-all-item">
            <img src={main8} alt="photo1" className="main__services-all-img" />
            <div className="main__services-all-text">
              <h2 className="main__services-all-title">
                Laver les tapis et moquettes
              </h2>
              <LinkMore
                path={'/usluga8'}
                style={{ position: 'relative', top: '-20px' }}
              />
            </div>
          </div>
          <div className="main__services-all-item">
            <img src={main9} alt="photo1" className="main__services-all-img" />
            <div className="main__services-all-text">
              <h2 className="main__services-all-title">
                Lavage des rideaux, rideaux et stores
              </h2>
              <LinkMore
                path={'/usluga9'}
                style={{ position: 'relative', top: '-20px' }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Services;
