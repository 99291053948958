import { MdOutlineArrowUpward } from 'react-icons/md';
import './LineUp.css';
import { useState } from 'react';

function LineUp() {
  const [isLineUp, setLineUp] = useState(false);

  const scrollUpHeader = () => {
    window.scroll({ top: 0, behavior: 'smooth' });
  };
  window.addEventListener('scroll', function () {
    // console.log(
    //   'innerHeight',
    //   window.innerHeight,
    //   'scrollY',
    //   window.scrollY,
    //   'повна висота',
    //   document.documentElement.scrollHeight
    // );
    // повна висота скрола на сторінці
    let fullHeight = document.documentElement.scrollHeight;

    // поточна висота скрола
    let currentlyHeight = window.innerHeight + window.scrollY;

    if (currentlyHeight + 100 >= fullHeight) {
      setLineUp(true);
    } else if (currentlyHeight + 100 < fullHeight) {
      setLineUp(false);
    }
  });

  return (
    <MdOutlineArrowUpward
      onClick={() => {
        scrollUpHeader();
      }}
      className={isLineUp ? 'lineup-img' : 'lineup-img-none'}
    />
  );
}

export default LineUp;
