import Header from '../../1_Header/Container/Header';
import Footer from '../../3_Footer/Footer';
import SwiperUslugi1 from './SwiperUslugi';
import UslugiList from '../../../UI/ListUslugi/ListUslugi';
import './Uslugi.css';

function UslugiImg() {
  return (
    <div className="mySwiper-uslugi">
      <SwiperUslugi1 />
    </div>
  );
}

function UslugiText() {
  return (
    <>
      <div className="uslugi__text">
        <h2>Laver les canapés Lyon</h2>
        <p>
          Le nettoyage à sec des meubles rembourrés par « PH Service » permet
          d'éliminer complètement la saleté et les odeurs désagréables du
          matériau. Il est rationnel de nettoyer une fois par an pour éviter que
          la saleté et la poussière ne s'accumulent dans la base textile.
          L'équipe de l'entreprise propose un nettoyage complet des canapés,
          fauteuils, fauteuils moelleux et autres types de meubles à l'aide
          d'équipements professionnels Karcher et de produits de nettoyage sûrs
          mais efficaces. Vous pouvez également commander chez nous l’ozonation
          de pièces de toute taille.
        </p>
        <h3>Comment nettoyer un canapé à Lyon:</h3>
        <ul>
          <li>
            Diagnostic du degré de salissure et sélection du produit de
            nettoyage pour meubles le plus efficace ;
          </li>
          <li>
            Éliminer les plus grosses particules de saleté et de poussière avec
            un aspirateur puissant ;
          </li>
          <li>
            Enlever les anciennes taches à l'aide de produits spéciaux
            (détachants) ;
          </li>
          <li>
            Appliquer un shampooing détergent sur les tissus d'ameublement des
            meubles ;
          </li>
          <li>
            Répartition minutieuse de la composition détergente et élimination
            des taches ;
          </li>
          <li>
            Nettoyage avec un système d'aspiration et un aspirateur haute
            pression ;
          </li>
          <li>Séchage</li>
        </ul>
      </div>
    </>
  );
}

function UslugiImgList() {
  return (
    <div className="uslugi">
      <div className="container">
        <div className="wrapper__uslugi">
          <div className="wrapper__img-text">
            <UslugiImg />
            <UslugiText />
          </div>
          <UslugiList />
        </div>
      </div>
    </div>
  );
}
function Usluga1() {
  const obj = {
    mainLang: 'PL',
    path: '/en/usluga1',
    langP: 'Poland',
    langEng: 'English',
  };
  return (
    <>
      <div className="body_wrapper">
        <Header obj={obj} />
        <UslugiImgList />
        <Footer />
      </div>
    </>
  );
}

export default Usluga1;
