import { Link } from 'react-router-dom';
import { FaPhone } from 'react-icons/fa6';
import './MyPhone.css';

function MyPhone(props) {
  const {
    classphonelink = 'header_phone_link',
    classphone = 'header_phone_p',
    icons = <FaPhone className="phone__logo" />,
  } = props;

  return (
    <Link to={'tel:+33767816986'} className={classphonelink}>
      <p {...props} className={classphone} title="phone">
        {icons} <span>Tel:</span> <span> +33 767816986</span>
      </p>
    </Link>
  );
}
export default MyPhone;
